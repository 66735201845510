import { HeadingPrimaryMd, HeadingPrimaryLg } from '/components/buildingBlocks/Heading'
import { useRelativeScroll } from '/machinery/useRelativeScroll'
import { animated } from 'react-spring'
import { ContainerLg } from '/components/buildingBlocks/Container'
import { useMediaQuery } from '@kaliber/use-media-query'

import mediaStyles from '/cssGlobal/media.css'

import styles from './ScrollCircle.css'

export function ScrollCircle({ content }) {
  const { ref: scrollRef, value } = useRelativeScroll()
  return (
    <div ref={scrollRef}>
      <ContainerLg>
        <div className={styles.component}>
          <Content {... { value }} layoutClassName={styles.contentLayout} items={content.content} />
          <Circle {... { value }} layoutClassName={styles.circleLayout} items={content.circle} />
        </div>
      </ContainerLg>
    </div>
  )
}

function Circle({ items, layoutClassName, value }) {
  const isViewportMd = useMediaQuery(mediaStyles.viewportMd) ?? false
  const TitleElement = isViewportMd ? HeadingPrimaryLg : HeadingPrimaryMd

  return (
    <div className={cx(styles.componentCircle, layoutClassName)}>
      <div className={styles.titles}>
        {items.map((x, i) => {
          const indexValue = (i + 1) * 0.25
          return (
            <animated.div
              key={i}
              className={styles.headingContainer}
              style={{ ...springValue({ value, indexValue }) }}
            >
              <TitleElement heading={x} h={4} />
            </animated.div>
          )
        })}
      </div>
      <div className={styles.background} role='presentation' />
    </div>
  )
}

function Content({ items, layoutClassName, value }) {
  const isViewportMd = useMediaQuery(mediaStyles.viewportMd) ?? false
  return (
    <div className={cx(styles.componentContent, layoutClassName)}>
      {items.map((x, i) => {
        const indexValue = (i + 1) * 0.25
        const animationStyle = isViewportMd
          ? springValue({ value, indexValue })
          : {}
        return  (
          <animated.p
            className={styles.text}
            style={animationStyle}
            key={i}
          >
            {x}
          </animated.p>
        )
      }
      )}
    </div>
  )
}

function springValue({ value, indexValue }) {
  return { opacity: value.to(val => (val >= indexValue && val < indexValue + 0.25 ? 1 : 0.1)) }
}
