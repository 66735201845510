import { useWindowEventListener } from './useWindowEventListener'
import { to, useScroll, useSpringValue } from 'react-spring'

export function useRelativeScroll() {
  const elementRef = React.useRef(null)

  const { scrollYProgress } = useScroll()
  const scrollHeight = useSpringValue(0)
  const offsetHeight = useSpringValue(0)
  const offsetTop = useSpringValue(0)

  useWindowEventListener('resize', handleResize)

  const value = to(
    [scrollHeight, scrollYProgress, offsetTop, offsetHeight],
    (height, progress, offTop, offHeight) => {
      if (height === 0) return 0

      const heightInPercentage = height / 100
      const startPosition = (offTop / heightInPercentage) - 10
      const endPosition = (offTop + (offHeight * 0.18)) / heightInPercentage
      const distance = (endPosition - startPosition) / 100
      const value = Math.min(distance, 1)
      const easedValue = (progress - (startPosition / 100)) * (1 / value)

      return Math.max(Math.min(easedValue, 1), 0)
    }
  )

  return {
    ref: elementRef,
    value
  }

  function handleResize() {
    if (document.documentElement.scrollHeight) scrollHeight.set(document.documentElement.scrollHeight)
    if (elementRef.current) offsetTop.set(elementRef.current.offsetTop)
    if (elementRef.current) offsetHeight.set(elementRef.current.offsetHeight)
  }
}
